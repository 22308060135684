@mixin img-fluid {
  max-width: 100%;
  height: auto;
}

@mixin img-retina($file-1x, $file-2x, $width-1x, $height-1x) {
  background-image: url($file-1x);

  @media only screen and (min-resolution: 192dpi), // IE9-11 don't support dppx
  only screen and (min-resolution: 2dppx) { // Standardized
    background-image: url($file-2x);
    background-size: $width-1x $height-1x;
  }
}
